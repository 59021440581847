export const MenuData = [
    // {
    //     name: "Home",
    //     href: "/",
    //     children: [
    //         {
    //             name: "Fashion",
    //             href: "/"
    //         },
    //         {
    //             name: "Furniture",
    //             href: "/furniture"
    //         },
    //         {
    //             name: "Electronics",
    //             href: "/electronics"
    //         },
    //         {
    //             name: "Grocery",
    //             href: "/grocery"
    //         },
    //         {
    //             name: "Pharmacy",
    //             href: "/pharmacy"
    //         },
    //         {
    //             name: "Jewllary",
    //             href: "/jewllary"
    //         },
    //         {
    //             name: "Baby Toys",
    //             href: "/baby-toys"
    //         },

    //     ]
    // },
    {
        name: "Artist Registarion",
        href: "/artist",
        mega_menu: false,
        children: [
          
        ]
    },
    {
        name: "Gallery Booking",
        href: "/gallerybooking",
        mega_menu: false,
        children: [
          
        ]
    },
    {
        name: "Events",
        href: "/month-calender",
        mega_menu: false,
        children: [
          
        ]
    },
    {
        name: "About",
        href: "/about",
        mega_menu: false,
        children: [
          
        ]
    },
    // {
    //     name: "Month Calender",
    //     href: "/month-calender",
    //     mega_menu: false,
    //     children: [
          
    //     ]
    // },
 
    // {
    //     name: "Shop",
    //     href: "#!",
    //     mega_menu: true,
    //     children: [
    //         {
    //             name: "Shop Layouts",
    //             children: [
    //                 {
    //                     name: "Shop Four Grid",
    //                     href: "/shop"
    //                 },
    //                 {
    //                     name: "Shop Three Grid",
    //                     href: "/shopTwo"
    //                 },
    //                 {
    //                     name: "Shop List View",
    //                     href: "/shoplist"
    //                 },
    //                 {
    //                     name: "Shop Left Sidebar",
    //                     href: "/shop-left-bar"
    //                 },
    //                 {
    //                     name: "Shop Right Sidebar",
    //                     href: "/shop-right-bar"
    //                 },
    //                 {
    //                     name: "Product Single",
    //                     href: "/product-details-one/1"
    //                 },
    //                 {
    //                     name: "Product Single Two",
    //                     href: "/product-details-two/1"
    //                 }
    //             ]
    //         },
    //         {
    //             name: "Other Pages",
    //             children: [
    //                 {
    //                     name: "Cart View One",
    //                     href: "/cart"
    //                 },
    //                 {
    //                     name: "Cart View Two",
    //                     href: "/cartTwo"
    //                 },
    //                 {
    //                     name: "Cart View Three",
    //                     href: "/cartThree"
    //                 },
    //                 {
    //                     name: "Empty Cart",
    //                     href: "/empty-cart"
    //                 },
    //                 {
    //                     name: "Checkout View One",
    //                     href: "/checkout-one"
    //                 },
    //                 {
    //                     name: "Checkout View Two",
    //                     href: "/checkout-two"
    //                 },
    //                 {
    //                     name: "Wishlist",
    //                     href: "/wishlist"
    //                 },
    //                 {
    //                     name: "Compare",
    //                     href: "/compare"
    //                 },
    //                 {
    //                     name: "Order Tracking",
    //                     href: "/order-tracking"
    //                 },
    //                 {
    //                     name: "Order Complete",
    //                     href: "/order-complete"
    //                 },
    //             ]
    //         }
    //     ]
    // },
    // {
    //     name: "Feature",
    //     href: "#!",
    //     children: [
    //         {
    //             name: "Product Hover",
    //             href: "/product-hover"
    //         },
    //         {
    //             name: "Order Success",
    //             href: "/order-success"
    //         },
    //         {
    //             name: " Email Template 1",
    //             href: "/email-template-one"
    //         },
    //         {
    //             name: "Email Template 2",
    //             href: "/email-template-two"
    //         },
    //         {
    //             name: " Email Template 3",
    //             href: "/email-template-three"
    //         },
    //         {
    //             name: " LookBook",
    //             href: "/lookbooks"
    //         },
    //         {
    //             name: "Invoice 1",
    //             href: "/invoice-one"
    //         },
    //         {
    //             name: " Invoice 2",
    //             href: "/invoice-two"
    //         }
    //     ]
    // },
    // {
    //     name: "Blog",
    //     href: "#!",
    //     children: [
    //         {
    //             name: " Blog Grid View One",
    //             href: "/blog-grid-three"
    //         },
    //         {
    //             name: "Blog Grid View Two",
    //             href: "/blog-grid-two"
    //         },
    //         {
    //             name: "Blog List View",
    //             href: "/blog-list-view"
    //         },
    //         {
    //             name: "Blog Single View One",
    //             href: "/blog-single-one"
    //         },
    //         {
    //             name: "Blog Single View TWo",
    //             href: "/blog-single-two"
    //         }
    //     ]
    // },
    {
        name: "Login",
        href: "/login",
        mega_menu: false,
        children: [
        ]
    }
    // {
    //     name: "Pages",
    //     href: "#!",
    //     children: [
    //         {
    //             name: "Vendor Dashboard",
    //             href: "/vendor-dashboard"
    //         },
    //         {
    //             name: "Customer Dashboard",
    //             href: "/my-account"
    //         },
    //         {
    //             name: "About Us",
    //             href: "/about"
    //         },
    //         {
    //             name: "Contact Us One",
    //             href: "/contact-one"
    //         },
    //         {
    //             name: "Contact Us Two",
    //             href: "/contact-two"
    //         },
    //         {
    //             name: "Frequently Questions",
    //             href: "/faqs"
    //         },
    //         {
    //             name: "Coming Soon",
    //             href: "/coming-soon"
    //         },
    //         {
    //             name: "Privacy Policy",
    //             href: "/privacy-policy"
    //         },
    //         {
    //             name: "Login",
    //             href: "/login"
    //         },
    //         {
    //             name: "404 Error",
    //             href: "/error"
    //         }

    //     ]
    // },
]
