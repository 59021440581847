import img1 from '../../../assets/img/instagram/post2.png'
import img2 from '../../../assets/img/instagram/post3.png'
import img3 from '../../../assets/img/instagram/post4.png'
import img4 from '../../../assets/img/instagram/post5.png'
import img5 from '../../../assets/img/instagram/post6.png'
import img6 from '../../../assets/img/instagram/post7.png'
import img7 from '../../../assets/img/instagram/post8.png'
import img8 from '../../../assets/img/instagram/post9.png'
import img9 from '../../../assets/img/instagram/post10.png'
import img10 from '../../../assets/img/instagram/post11.png'
import img11 from '../../../assets/img/instagram/post12.png'

export const InstgramData = [
    {
        img: img1,
        icon: "fa fa-instagram"
    },
    {
        img: img2,
        icon: "fa fa-instagram"
    },
    {
        img: img3,
        icon: "fa fa-instagram"
    },
    {
        img: img4,
        icon: "fa fa-instagram"
    },
    {
        img: img5,
        icon: "fa fa-instagram"
    },
    {
        img: img6,
        icon: "fa fa-instagram"
    },
    {
        img: img7,
        icon: "fa fa-instagram"
    },
    {
        img: img8,
        icon: "fa fa-instagram"
    },
    {
        img: img9,
        icon: "fa fa-instagram"
    },
    {
        img: img10,
        icon: "fa fa-instagram"
    },
    {
        img: img11,
        icon: "fa fa-instagram"
    }
]
